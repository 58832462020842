export const DEBOUNCE_DELAY_MS = 350

export const ENABLE_ANOTHER_ROUND_FLAG = 'frontend.enable-another-round'

export const KOUNTA_EXTERNAL_SETTINGS_FLAG =
  'manage-api.kounta.use-external-settings'

/**
 * TODO(SRV-2393): Clean up feature flag
 */
export const ZENDESK_AUTH_FLAG = 'manage-frontend.zendesk-auth'

export const AUTH0_FLAG = 'manage.auth0'

export const SURFACE_BATCH_ERRORS_FLAG =
  'manage-frontend.enable-surface-batch-errors'

// TODO https://linear.app/mr-yum/issue/SRV-3004/remove-flag-for-user-management
export const ENABLE_EXTERNAL_STAFF_MANAGEMENT =
  'manage-frontend.enable-external-staff-management'

export const ENABLE_EXTERNAL_QR_CODE_MANAGEMENT =
  'manage-frontend.enable-external-qr-code-management'

export const ENABLE_CREW_FAVOURITES_TOGGLE =
  'manage-frontend.menu-item-favourite'

export const ENABLE_VENUE_AND_ORGANIZATION_SEARCH =
  'manage-frontend.enable-venue-and-organization-search'

export const ENABLE_SISENSE_FUSION_EMBED_PAGES =
  'manage-frontend.enable-sisense-fusion-embed'

export const ENABLE_POS_SYNC_BANNER = 'manage-frontend.enable-pos-sync-banner'

export const ENABLE_PRICE_ADJUSTMENT_CUSTOM_DISPLAY_PRICE =
  'frontend.enable-price-adjustment-custom-display-price'
