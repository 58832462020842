import { StaffRole } from 'lib/gql'

export const FEATURE_PERMISSIONS = {
  modifierGroup: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  reward: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  rewardActivation: {
    writeRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  /**
   * Enables read access to general venue queries that all users need.
   * Distinct from venueInfoPage which controls read/write to the venue info page
   */
  venueData: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
      StaffRole.DeliveryStaff,
    ],
  },
  venueInfoPage: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  venueSetPin: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  venueSetupConfig: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  revealVenuePin: {
    readRoles: [StaffRole.Admin],
  },
  venueActivation: {
    writeRoles: [StaffRole.Admin],
  },
  venueGuests: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  homeAndBranding: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  venueBrandingColors: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  openingHours: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  integrationSetup: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  integrationDeleteExternlWebhook: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  discountsAndSurcharges: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  allDiscounts: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  meanduAttributes: {
    writeRoles: [StaffRole.Admin],
  },
  tableGroups: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  qrCodes: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  menuItems: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  menuItemAvailability: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
  },
  menuItemMegaOrderAction: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  menuItemDeleteMegaOrderAction: {
    writeRoles: [StaffRole.Admin],
  },
  menuItemTranslations: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  menuItemCopyImages: { writeRoles: [StaffRole.Admin] },
  menuItemIncludeInSmartSuggestion: { writeRoles: [StaffRole.Admin] },
  menuItemBulkImportSyncItems: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  menuItemBulkImportForceSyncItems: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  menuItemBulkImportIgnoreItems: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  menuCategory: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  cloneMenuCategoryFromAnotherVenue: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  menuSection: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  linkMenuSectionToMenuCategory: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  upsells: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  upsellGroup: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  dynamicUpsell: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  upgradeGroup: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  dailySpecials: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  priceLevels: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
  },
  liveOrders: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
      StaffRole.DeliveryStaff,
    ],
  },
  orderHistory: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  orderBatchInsights: { writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder] },
  rejectedOrders: {
    readRoles: [StaffRole.Admin],
  },
  completeOrders: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  commissionRate: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  waitTimes: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
  },
  orderAvailability: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  orderingTypeSettings: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  orderingTypesTurnAllOn: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
  },
  orderingWindows: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  tipping: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  tippingSkipGratuity: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  notifications: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  groupOrdering: {
    writeRoles: [StaffRole.Admin],
  },
  marketingOptIn: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  customerDatabase: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  socialMediaIntegrations: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  tableBooking: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  venuePerformanceDashboard: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  venuePerformanceMultiVenuesDashboard: {
    readRoles: [StaffRole.Owner],
  },
  authLogoutStaffFromAllDevices: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  salesReport: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  salesReportEmailGuestRecords: {
    writeRoles: [StaffRole.Admin, StaffRole.Owner, StaffRole.VenueStaff],
  },
  salesReportPayoutExport: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  tableSpendReport: {
    writeRoles: [StaffRole.Admin, StaffRole.Owner, StaffRole.VenueStaff],
  },
  payouts: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  payoutsCreation: {
    writeRoles: [StaffRole.Admin],
  },
  advancedAnalytics: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  billing: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  paymentSettings: {
    writeRoles: [StaffRole.Admin],
  },
  bankDetails: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  bankDetailsAccountUpdate: {
    writeRoles: [StaffRole.Admin],
  },
  stripeConnect: {
    writeRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  stripeConnectedAccount: {
    writeRoles: [StaffRole.Admin],
  },
  stripeConnectReview: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  printerSetup: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  printerJobs: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  printerGroupPage: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  printerGroupData: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  batchingGroups: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  menuConfig: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
  },
  organizations: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationInfo: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationVenues: {
    readRoles: [StaffRole.Admin, StaffRole.Owner],
    writeRoles: [StaffRole.Admin],
  },
  organizationSearch: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationStaff: {
    readRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  organizationReward: {
    writeRoles: [StaffRole.Admin],
  },
  organizationRewardMemberships: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationCRM: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizaionIntegration: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationStripeConnectPage: {
    readRoles: [StaffRole.Admin],
  },
  organizationYumpingo: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationCreate: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationReport: {
    writeRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  organizationManualUploadOrders: {
    writeRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  organizationManualUpdatePoints: {
    writeRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  organizationsWithCRMAuths: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  organizationsSideNavBar: {
    readRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  organizationOauth2Tokens: {
    readRoles: [StaffRole.Admin, StaffRole.Owner],
  },
  loyaltyMetadata: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  venueCreation: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  venues: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  landingPages: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  meanduAdminPage: {
    readRoles: [StaffRole.Admin],
  },
  accountManagerInfo: {
    writeRoles: [StaffRole.Admin],
  },
  salesAgentInfo: {
    writeRoles: [StaffRole.Admin],
  },
  posReferrerInfo: {
    writeRoles: [StaffRole.Admin],
  },
  venueCategoryInfo: {
    writeRoles: [StaffRole.Admin],
  },
  qrRedirectsPage: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  users: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  userOrganizations: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.DeliveryStaff,
    ],
  },
  staffCreation: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  staff: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder],
  },
  staffImport: { writeRoles: [StaffRole.Admin] },
  helpCentre: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
  },
  customerImport: { writeRoles: [StaffRole.Admin] },
  tierInformation: {
    writeRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  crewClient: {
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
  feedbackPortal: {
    readRoles: [StaffRole.Admin, StaffRole.MenuBuilder, StaffRole.Owner],
  },
  priceAdjustments: {
    readRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
      StaffRole.ServiceStaff,
    ],
    writeRoles: [
      StaffRole.Admin,
      StaffRole.MenuBuilder,
      StaffRole.Owner,
      StaffRole.VenueStaff,
    ],
  },
} as const
